<template>
  <TheStart></TheStart>
</template>

<script>
import TheStart from './components/TheStart.vue';
import './assets/tailwind.css';
export default {
  name: 'App',
  components: {
    TheStart
  }
}
</script>

<style >
.container {
  @apply max-w-[1440px] mx-auto px-5 w-full;
}

body {
  @apply bg-gray-500;
}

#app::after {
  @apply content-[''];
  @apply w-full h-full absolute inset-0 bg-black opacity-50;
}

#app::before {
  @apply content-[''];
  @apply absolute inset-0;
  @apply bg-center bg-no-repeat max-md:bg-cover bg-100p;


  background-image: url('./assets/ballons.jpg');
}

#app {
  @apply relative;

  font-family: Avenir,
    Helvetica,
    Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh;
}
</style>
