<template>
    <div class="relative z-10 ease-in-out duration-300"
        :class="[showDialog ? 'opacity-100 visible' : 'opacity-0 invisible']" aria-labelledby="modal-title" role="dialog"
        aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed left-0 top-[20%] z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ease-in-out duration-300"
                :class="showModalClasses">

                <div
                    class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">


                    <ModalDialogForm @submitData="toggleSuccesState" v-show="!showSuccessNotification"></ModalDialogForm>
                    <ModalDialogSuccess @closeDialog="{ $emit('closeDialog'); }" v-show="showSuccessNotification">
                    </ModalDialogSuccess>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModalDialogForm from "./ModalDialogForm.vue";
import ModalDialogSuccess from "./ModalDialogSuccess.vue";


export default {
    data() {
        return {
            showSuccessNotification: false,
        };
    },
    components: {
        ModalDialogForm,
        ModalDialogSuccess
    },
    emits: ['closeDialog'],
    props: { showDialog: Boolean },
    methods: {
        toggleSuccesState() {
            this.showSuccessNotification = !this.showSuccessNotification;
        }
    },
    computed: {
        showModalClasses() {
            if (!this.showDialog) {
                return ['opacity-0', 'translate-y-4', 'sm:translate-y-0', 'sm:scale-95'];
            }
            else {
                return ['opacity-100', 'translate-y-0', 'sm:scale-100'];
            }
        }
    },

};

</script>