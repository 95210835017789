import { initializeApp } from 'firebase/app'
// ... other firebase imports


const firebaseConfig = {
  apiKey: "AIzaSyDf4B06NTgr9f5xnenE1JIiGL909nuew4A",
  authDomain: "bday-9c182.firebaseapp.com",
  projectId: "bday-9c182",
  storageBucket: "bday-9c182.appspot.com",
  messagingSenderId: "378709441477",
  appId: "1:378709441477:web:c6680909151af7ea66e9ac"
};

export const firebaseApp = initializeApp(firebaseConfig);