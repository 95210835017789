<template>
    <div>
        <p class="text-black font-bold mb-10">
            Bitte gib hier die Namen der Personen ein, die kommen, damit ich bezüglich des Essens und der
            Getränke besser planen kann :).
        </p>

        <form @submit.prevent="submit">
            <div>
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">
                    Personen:
                </label>

                <div class="flex flex-col mb-2 gap-y-2">
                    <div v-for="(input, index) in friends" :key="`friendInput-${index}`"
                        class="input wrapper flex items-center text-black">
                        <input v-model="input.friend" type="text" class="h-10 rounded-lg outline-none p-2 w-full border"
                            placeholder="Alex" />
                        <!--          Add Svg Icon-->
                        <svg @click="addField(input, friends)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                            width="24" height="24" class="ml-2 cursor-pointer">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="green"
                                d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                        </svg>

                        <svg v-show="friends.length > 1" @click="removeField(index, friends)"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                            class="ml-2 cursor-pointer">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path fill="#EC4899"
                                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                        </svg>
                    </div>
                </div>
            </div>

            <div class="rounded-md bg-yellow-50 mb-4 p-4 transition-all duration-300"
                :class="[validateFriends ? 'opacity-0' : 'opacity-100']">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">Fülle bitte das Formular aus:</h3>
                        <div class="mt-2 text-sm text-red-700">
                            <ul role="list" class="list-disc space-y-1 pl-5">
                                <li>Solltest du mehrere Name eintragen wollen, klicke einfach auf das Plus.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <button type="submit" :disabled="!validateFriends"
                class="inline-flex w-full justify-center disabled:cursor-not-allowed disabled:bg-gray-300 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Absenden
            </button>
        </form>
    </div>
</template>

<script>
import { collection, addDoc } from "firebase/firestore";
import { getFirestore } from 'firebase/firestore';

export default {
    data() {
        return {
            friends: [{ friend: "" }],
            selected: null,
        };

    },
    emits: ['submitData'],
    methods: {
        addField(value, fieldType) {
            fieldType.push({ friend: "" });
        },
        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },
        submit() {
            this.$emit('submitData');

            this.createInvitation();
        },

        async createInvitation() {
            const colRef = collection(getFirestore(), 'invitation');
            const dataObj = {
                names: this.friends,
            };

            const docRef = await addDoc(colRef, dataObj);

            console.log("Doc createt", docRef.id);
        }
    },
    computed: {
        validateFriends() {
            for (const friend of this.friends) {
                if (friend.friend.trim() === '') {
                    return false; // Return false if any friend name is empty
                }
            }
            return true; // Return true if all friend names are valid
        },
    }
}
</script>