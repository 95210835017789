<template>
    <div>
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
        </div>
        <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                Vielen Dank für deine Zusage! :)
            </h3>
            <div class="mt-2">
                <p class="text-sm text-gray-500">
                    Danke, ich freue mich darauf, dich/euch am 1.4. zu sehen!
                </p>
            </div>

            <button @click="closeDialog"
                class="inline-flex mt-5 w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Schließen
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    emits: ['closeDialog'],
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>