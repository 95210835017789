import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import { VueFire } from 'vuefire'
import { firebaseApp } from './firebase/init.js';

const app = createApp(App);

app.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
});

app.mount('#app');